import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import useTasks from 'hooks/useTasks';
import ChecklistTree from 'components/ChecklistTree';

const WorkOrdersSection = () => {
  const { values, setFieldValue } = useFormikContext();
  const seasonIdsCommaSeparated = useMemo(
    () =>
      values.selectedCropSeasons?.map(cropSeason => cropSeason?.id).join(',') ??
      '',
    [values.selectedCropSeasons]
  );

  const cropZoneIdsCommaSeparated = useMemo(
    () => values.selectedCropzones?.map(({ id }) => id).join(',') ?? '',
    [values.selectedCropzones]
  );

  const initialPayload = { size: 1 };
  if (seasonIdsCommaSeparated.length && cropZoneIdsCommaSeparated.length) {
    initialPayload.seasonIdsCommaSeparated = seasonIdsCommaSeparated;
    initialPayload.cropZoneIdsCommaSeparated = cropZoneIdsCommaSeparated;
    initialPayload.size = 0;
  }
  const { loadTasks, loading, tasks } = useTasks(initialPayload);
  const [checked, setChecked] = useState([]);
  const [numDisplayedTasks, setNumDisplayedTasks] = useState(0);

  useEffect(() => {
    if (seasonIdsCommaSeparated.length && cropZoneIdsCommaSeparated.length) {
      loadTasks({
        cropZoneIdsCommaSeparated,
        seasonIdsCommaSeparated,
        size: 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonIdsCommaSeparated, cropZoneIdsCommaSeparated]);

  // Map API response to tree data
  const treeData = useMemo(() => {
    if (loading || !tasks?.length) return [];

    return tasks
      .filter(task => task.status === 'ready')
      .map(task => ({
        key: task.taskId,
        title: task.name
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [tasks, loading]);

  // Update form values with checked items
  useEffect(() => {
    if (!tasks?.length || !seasonIdsCommaSeparated.length) return;

    setFieldValue('selectedWorkOrders', checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  let numWorkOrdersDisplay = '';
  if (!loading) {
    numWorkOrdersDisplay = `(${numDisplayedTasks})`;
  }

  return (
    <div className="flex flex-col items-center bg-white">
      {seasonIdsCommaSeparated.length > 0 &&
        cropZoneIdsCommaSeparated.length > 0 && (
          <div className="w-full">
            <h1
              className="font-body font-semibold text-xl"
              style={{ color: '#14151C' }}
            >
              Ready Tasks {numWorkOrdersDisplay}
            </h1>
            <ChecklistTree
              treeData={treeData}
              loading={loading}
              checked={checked}
              setChecked={setChecked}
              setNumResults={setNumDisplayedTasks}
            />
          </div>
        )}
    </div>
  );
};

export default WorkOrdersSection;
