import { useFlags } from 'launchdarkly-react-client-sdk';

const parseEnvId = id => (id?.includes(',') ? id.split(',') : id);

export const ReportCategories = {
  AppliedProducts: 'Applied Products',
  Fields: 'Fields',
  FSA: 'FSA',
  Excel: 'Excel',
  Products: 'Products',
  Properties: 'Properties',
  ProductionRecords: 'Production Records',
  YieldAndProfitability: 'Yield and Profitability'
};
// These values will be displayed in the left nav
export const ReportSubTypes = {
  ByDay: 'By Day',
  ByField: 'By Field',
  CropDetails: 'By Crop Details',
  CropLayout: 'Crop Layout',
  CropZoneInfo: 'Crop Zone Info',
  Regulatory: 'Regulatory',
  Details: 'Details',
  Dicamba: 'Dicamba',
  ExcelReport: 'Excel Report',
  FertilizerByCrop: 'Fertilizer Report',
  FSA: 'FSA Report',
  InventorySummary: 'Inventory Summary',
  NoticeOfApplication: 'Notice of Application',
  PesticideUsage: 'Pesticide Use',
  PHI: 'PHI Report',
  PlansSummary: 'Plans Summary',
  Profitability: 'Profitability',
  ApplicatorMap: 'Applicator Map',
  PrintInvoices: 'Print Invoices',
  PrintPlans: 'Print Plans',
  PrintRecommendations: 'Print Recommendations',
  PrintTasks: 'Print Tasks',
  REI: 'REI Report',
  Summary: 'Summary',
  YieldProduction: 'Yield Production'
};

export const getReport = (reports, category, subType) =>
  reports.find(
    report => report.category === category && report.subType === subType
  );

export const getReportByName = (reports, _name) =>
  reports.find(({ name }) => name === _name);

export const getReportsByCategory = (reports, _category) =>
  reports
    .filter(({ category }) => category === _category)
    .sort((a, b) => a.subType.localeCompare(b.subType));

export const reportFlagNames = {
  BY_FIELD: 'feReportOptionByField',
  APPLIED_PRODUCTS_BY_CROP_DETAILS:
    'feReportOptionAppliedProductsByCropDetails',
  APPLIED_PRODUCTS_BY_DAY: 'feReportOptionAppliedProductsByDay',
  APPLIED_PRODUCTS_DETAILS: 'feReportOptionAppliedProductsDetails',
  APPLIED_PRODUCTS_DICAMBA: 'feReportOptionAppliedProductsDicamba',
  APPLIED_PRODUCTS_FERTILIZER_BY_CROP:
    'feReportOptionAppliedProductsFertilizerByCrop',
  PESTICIDE_USAGE: 'feReportOptionPesticideUsage',
  APPLIED_PRODUCTS_PHI: 'feReportOptionAppliedProductsPhi',
  APPLIED_PRODUCTS_REI: 'feReportOptionAppliedProductsRei',
  APPLIED_PRODUCTS_SUMMARY: 'feReportOptionAppliedProductsSummary',
  CROP_LAYOUT: 'feReportOptionCropLayout',
  FSA: 'feReportOptionFsa',
  INVENTORY_SUMMARY: 'feReportOptionInventorySummary',
  NOTICE_OF_APPLICATION: 'feReportOptionNoticeOfApplication',
  PLANS_SUMMARY: 'feReportOptionPlansSummary',
  APPLICATOR_MAP: 'feReportOptionApplicatorMap',
  PRINT_INVOICES: 'feReportOptionPrintInvoices',
  PRINT_PLANS: 'feReportOptionPrintPlans',
  PRINT_RECOMMENDATIONS: 'feReportOptionPrintRecommendations',
  PRINT_TASKS: 'feReportOptionPrintTasks',
  PROPERTIES_BY_CROP_ZONE_INFO: 'feReportOptionPropertiesByCropZoneInfo',
  REGULATORY: 'feReportOptionRegulatory',
  YIELD_AND_PROFITABILITY_BY_PROFITABILITY:
    'feReportOptionYieldAndProfitabilityByProfitability',
  EXCEL_REPORT: 'feReportOptionExcelReport',
  YIELD_AND_PROFITABILITY_YIELD_PRODUCTION:
    'feReportOptionYieldAndProfitabilityYieldProduction'
};

export default () => {
  const flags = useFlags();

  const reports = [
    {
      name: 'Applied Products By Field',
      featureFlag: flags[reportFlagNames.BY_FIELD],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.ByField,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_FIELD_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_FIELD_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products By Crop Layout',
      featureFlag: flags[reportFlagNames.APPLIED_PRODUCTS_BY_CROP_DETAILS],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.CropDetails,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_CROP_DETAILS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_CROP_DETAILS_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products By Day',
      featureFlag: flags[reportFlagNames.APPLIED_PRODUCTS_BY_DAY],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.ByDay,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_DAY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_BY_DAY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products Details',
      featureFlag: flags[reportFlagNames.APPLIED_PRODUCTS_DETAILS],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.Details,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_DETAILS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_DETAILS_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products Dicamba',
      featureFlag: flags[reportFlagNames.APPLIED_PRODUCTS_DICAMBA],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.Dicamba,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_DICAMBA_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_DICAMBA_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Fertilizer Report',
      featureFlag: flags[reportFlagNames.APPLIED_PRODUCTS_FERTILIZER_BY_CROP],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.FertilizerByCrop,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_FERTILIZER_BY_CROP_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env
            .REACT_APP_APPLIED_PRODUCTS_FERTILIZER_BY_CROP_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Pesticide Use',
      featureFlag: flags[reportFlagNames.PESTICIDE_USAGE],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.PesticideUsage,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PESTICIDE_USAGE_BY_CROP_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PESTICIDE_USAGE_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'PHI Report',
      featureFlag: flags[reportFlagNames.APPLIED_PRODUCTS_PHI],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.PHI,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_PHI_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_PHI_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products Regulatory',
      featureFlag: flags[reportFlagNames.REGULATORY],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.Regulatory,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_REGULATORY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_REGULATORY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products REI Report',
      featureFlag: flags[reportFlagNames.APPLIED_PRODUCTS_REI],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.REI,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_REI_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_REI_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applied Products Summary',
      featureFlag: flags[reportFlagNames.APPLIED_PRODUCTS_SUMMARY],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.Summary,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_SUMMARY_DETAILS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLIED_PRODUCTS_SUMMARY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Excel Report',
      featureFlag: flags[reportFlagNames.EXCEL_REPORT],
      category: ReportCategories.Excel,
      subType: ReportSubTypes.ExcelReport,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showExcelAlert: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(process.env.REACT_APP_EXCEL_REPORT_DATASET_ID),
        reportId: parseEnvId(process.env.REACT_APP_EXCEL_REPORT_EMBED_REPORT_ID)
      }
    },
    {
      name: 'Crop Layout',
      featureFlag: flags[reportFlagNames.CROP_LAYOUT],
      category: ReportCategories.Properties,
      subType: ReportSubTypes.CropLayout,
      formConfig: {
        showCropSeasons: true,
        showDateRange: false,
        showProperties: true,
        showProducts: false
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_FIELDS_BY_CROP_LAYOUT_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_FIELDS_BY_CROP_LAYOUT_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'FSA Report',
      featureFlag: flags[reportFlagNames.FSA],
      category: ReportCategories.FSA,
      subType: ReportSubTypes.FSA,
      formConfig: {
        showCropSeasons: true,
        showDateRange: false,
        showProperties: true,
        showProducts: false
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(process.env.REACT_APP_FSA_REPORT_DATASET_ID),
        reportId: parseEnvId(process.env.REACT_APP_FSA_REPORT_EMBED_REPORT_ID)
      }
    },
    {
      name: 'Notice of Application',
      featureFlag: flags[reportFlagNames.NOTICE_OF_APPLICATION],
      category: ReportCategories.AppliedProducts,
      subType: ReportSubTypes.NoticeOfApplication,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_NOTICE_OF_APPLICATION_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_NOTICE_OF_APPLICATION_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Applicator Map',
      featureFlag: flags[reportFlagNames.APPLICATOR_MAP],
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.ApplicatorMap,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showWorkOrders: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(process.env.REACT_APP_APPLICATOR_MAP_DATASET_ID),
        reportId: parseEnvId(
          process.env.REACT_APP_APPLICATOR_MAP_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Print Invoices',
      featureFlag: flags[reportFlagNames.PRINT_INVOICES],
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.PrintInvoices,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: false,
        showTimezoneOffset: true,
        showPrintAlert: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_INVOICES_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env
            .REACT_APP_PRODUCTION_RECORDS_PRINT_INVOICES_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Print Plans',
      featureFlag: flags[reportFlagNames.PRINT_PLANS],
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.PrintPlans,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: false,
        showTimezoneOffset: true,
        showPrintAlert: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_PLANS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_PLANS_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Print Recommendations',
      featureFlag: flags[reportFlagNames.PRINT_RECOMMENDATIONS],
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.PrintRecommendations,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: false,
        showTimezoneOffset: true,
        showPrintAlert: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env
            .REACT_APP_PRODUCTION_RECORDS_PRINT_RECOMMENDATIONS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env
            .REACT_APP_PRODUCTION_RECORDS_PRINT_RECOMMENDATIONS_EMBED_REPORT_ID
        )
      }
    },

    {
      name: 'Print Tasks',
      featureFlag: flags[reportFlagNames.PRINT_TASKS],
      category: ReportCategories.ProductionRecords,
      subType: ReportSubTypes.PrintTasks,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: false,
        showTimezoneOffset: true,
        showPrintAlert: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_TASKS_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PRODUCTION_RECORDS_PRINT_TASKS_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Inventory Summary',
      featureFlag: flags[reportFlagNames.INVENTORY_SUMMARY],
      category: ReportCategories.Products,
      subType: ReportSubTypes.InventorySummary,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: false,
        showProducts: true,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTS_INVENTORY_SUMMARY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PRODUCTS_INVENTORY_SUMMARY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Products Plan Summary',
      featureFlag: flags[reportFlagNames.PLANS_SUMMARY],
      category: ReportCategories.Products,
      subType: ReportSubTypes.PlansSummary,
      formConfig: {
        showCropSeasons: true,
        showDateRange: false,
        showProperties: false,
        showProducts: false,
        showPlans: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PRODUCTS_PLAN_SUMMARY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PRODUCTS_PLAN_SUMMARY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Profitability',
      featureFlag:
        flags[reportFlagNames.YIELD_AND_PROFITABILITY_BY_PROFITABILITY],
      category: ReportCategories.YieldAndProfitability,
      subType: ReportSubTypes.Profitability,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_Y_AND_P_PROFITABILITY_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_Y_AND_P_PROFITABILITY_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Crop Zone Info',
      featureFlag: flags[reportFlagNames.PROPERTIES_BY_CROP_ZONE_INFO],
      category: ReportCategories.Properties,
      subType: ReportSubTypes.CropZoneInfo,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_PROPERTIES_BY_CROP_ZONE_INFO_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_PROPERTIES_BY_CROP_ZONE_INFO_EMBED_REPORT_ID
        )
      }
    },
    {
      name: 'Yield Production',
      featureFlag:
        flags[reportFlagNames.YIELD_AND_PROFITABILITY_YIELD_PRODUCTION],
      category: ReportCategories.YieldAndProfitability,
      subType: ReportSubTypes.YieldProduction,
      formConfig: {
        showCropSeasons: true,
        showDateRange: true,
        showProperties: true,
        showProducts: false,
        showTimezoneOffset: true
      },
      reportConfig: {
        isPaginated: true,
        groupId: parseEnvId(process.env.REACT_APP_POWERBI_GROUP_ID),
        datasetId: parseEnvId(
          process.env.REACT_APP_Y_AND_P_YIELD_PRODUCTION_DATASET_ID
        ),
        reportId: parseEnvId(
          process.env.REACT_APP_Y_AND_P_YIELD_PRODUCTION_EMBED_REPORT_ID
        )
      }
    }
  ];

  return reports;
};
