import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import CropIconMapping from 'components/CropIconMapping';
import { scaleToMapPDF as scale } from 'screens/Property/helpers/mapApiHelpers';
import formatNumber from 'helpers/formatNumber';

export const renderCropLegendRows = ({ crops, cropColors, size = 'small' }) => {
  const isSmall = size === 'small';
  const fontSize = `${scale(isSmall ? 8 : 12)}px`;
  const lineHeight = `${scale(isSmall ? 14 : 20)}px`;
  const rowClasses = isSmall ? '' : 'border-b border-neutral-20';
  const rowStyles = isSmall
    ? { height: scale(14) }
    : { height: scale(24), paddingBottom: scale(1) };
  return crops.map((crop, i) => (
    <Row
      key={crop.id}
      className={rowClasses}
      style={{ marginTop: i > 0 ? scale(4) : 0, ...rowStyles }}
      align="middle"
      gutter={scale(2)}
    >
      <Col>
        <div
          style={{
            background: cropColors[crop.id],
            width: lineHeight,
            height: lineHeight
          }}
        />
      </Col>
      <Col>
        <CropIconMapping size={scale(12)} cropObject={crop} />
      </Col>
      <Col
        flex="auto"
        style={{
          fontSize,
          lineHeight
        }}
      >
        {crop.name}
      </Col>
      <Col
        style={{
          fontSize,
          lineHeight
        }}
      >
        {formatNumber(crop.area, 'ac')}
      </Col>
    </Row>
  ));
};

const CropLegend = ({ crops, cropColors, show, style, limit }) => {
  if (!show) return null;

  const limitedCrops = limit ? crops.slice(0, limit) : crops;

  return (
    <div
      className="absolute z-2 bg-white border-neutral-60"
      style={{
        width: scale(200),
        padding: scale(8),
        paddingBottom: scale(16),
        borderWidth: scale(0.5),
        ...style
      }}
    >
      <Row
        justify="center"
        style={{
          marginBottom: scale(8),
          fontSize: scale(10),
          lineHeight: `${scale(16)}px`,
          fontWeight: 500
        }}
      >
        Map Legend
      </Row>
      {renderCropLegendRows({ crops: limitedCrops, cropColors })}
    </div>
  );
};

CropLegend.propTypes = {
  crops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      area: PropTypes.number.isRequired
    })
  ).isRequired,
  cropColors: PropTypes.objectOf(PropTypes.string).isRequired,
  show: PropTypes.bool,

  style: PropTypes.objectOf(),
  limit: PropTypes.number
};

CropLegend.defaultProps = {
  show: true,

  style: {},
  limit: 0
};

export default CropLegend;
