import React from 'react';
import { ReactComponent as AlertIcon } from 'assets/alertIcon.svg';

const ExcelAlert = () => {
  return (
    <div className="border-banner-alert-blue-30 border rounded bg-banner-alert-blue-10 mb-32px">
      <div className="py-12px px-16px">
        <div className="flex content-start gap-5 pb-4px">
          <AlertIcon />
          <h4 className="text-md whitespace-no-wrap">Excel Report Includes:</h4>
        </div>
        <ul className="text-neutral-60 text-sm ml-36px">
          <li>• Property Information</li>
          <li>• PlansSection</li>
          <li>• Recommendations</li>
          <li>• Tasks</li>
          <li>• Invoices</li>
        </ul>
      </div>
    </div>
  );
};

export default ExcelAlert;
