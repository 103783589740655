import React, { useContext } from 'react';
import { GeoJSONLayer } from 'react-mapbox-gl';
import { featureCollection } from '@turf/helpers';
import { cloneDeep, isEmpty } from 'lodash';

import { Context } from 'components/Store';
import {
  calculateMapPDFScale,
  getCentroidGeoJSON
} from 'screens/Property/helpers/mapApiHelpers';
import {
  conditionFieldWithCropZones,
  customSymbolLayout,
  customSymbolPaint,
  legendSymbolLayout
} from '../../../PropertyMap/utilities/mapboxLayerHelpers';

export const CustomLayers = ({
  geoJSONArr,
  stylesArr,
  onClickShape,
  isMultipleSelect,
  showLegendLabels
}) => {
  const [{ isExportPDFMapsActive, exportPDFMapsStep }] = useContext(Context);
  return geoJSONArr.map((features, idx) => {
    if (!stylesArr[idx] || isEmpty(stylesArr[idx])) return null;

    const uniqueGeometries = new Set();

    let sortedFeatures = features
      .sort((a, b) => {
        if (!a.properties.sortKey) return -1;
        if (!b.properties.sortKey) return 1;
        return b.properties.sortKey - a.properties.sortKey;
      })
      .map(feature => {
        const _feature = feature;
        _feature.properties.symbolSortKey =
          features.length - feature.properties.sortKey;
        return _feature;
      });

    // In export preview, remove labels from overlapping crop zones
    if (isExportPDFMapsActive && exportPDFMapsStep === 1) {
      sortedFeatures = sortedFeatures
        // Reverse the array so that the highest sortKey feature keeps its label
        .reverse()
        // Remove labels from crop zones with the same geometry as a previous crop zone
        .map(feature => {
          if (feature.properties.$landType !== 'cropzone') return feature;

          const geometryString = JSON.stringify(feature.geometry);
          const newFeature = cloneDeep(feature);
          if (uniqueGeometries.has(geometryString)) {
            newFeature.properties.customLabel = undefined;
            newFeature.properties.labelLegendKey = undefined;
          } else {
            uniqueGeometries.add(geometryString);
          }
          return newFeature;
        })
        // Reverse the array back to its original order
        .reverse();
    }

    const fc = featureCollection(sortedFeatures);
    const centroids = getCentroidGeoJSON(fc);
    const { fillColor, fillOutlineColor } = stylesArr[idx];

    const fillPaint = isMultipleSelect
      ? {
          'fill-color': [
            'case',
            conditionFieldWithCropZones,
            'rgba(20, 21, 28, 0.3)',
            ['has', 'cropColor'],
            ['get', 'cropColor'],
            fillColor
          ]
        }
      : {
          'fill-color': fillColor,
          'fill-outline-color': fillOutlineColor
        };

    const linePaint = isMultipleSelect
      ? {
          'line-color': [
            'case',
            ['has', 'cropColor'],
            'transparent',
            conditionFieldWithCropZones,
            '#14151C',
            fillOutlineColor
          ],
          'line-width': ['case', ['has', 'cropColor'], 0, 2]
        }
      : {};

    const symbolLayout = showLegendLabels
      ? legendSymbolLayout
      : customSymbolLayout;

    if (isExportPDFMapsActive) {
      symbolLayout['text-size'] = exportPDFMapsStep === 0 ? 14 : 10;
      if (exportPDFMapsStep === 1) {
        symbolLayout['text-size'] *= calculateMapPDFScale();
      }
    }

    return (
      <>
        <GeoJSONLayer
          key={`line-${features[0]?.properties?.id || idx}`}
          data={fc}
          type="line"
          linePaint={linePaint}
        />
        <GeoJSONLayer
          key={`fill-${features[0]?.properties?.id || idx}`}
          data={fc}
          type="fill"
          fillOnClick={onClickShape}
          fillPaint={{
            ...fillPaint,
            'fill-antialias': true
          }}
        />
        <GeoJSONLayer
          key={`symbol-${features[0]?.properties?.id || idx}`}
          data={centroids}
          symbolLayout={symbolLayout}
          symbolPaint={customSymbolPaint}
        />
      </>
    );
  });
};

export default CustomLayers;
