import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import { FETCH_TYPES } from 'helpers/constants';
import usePopulateMapData from 'hooks/usePopulateMapData';
import ChecklistTree from 'components/ChecklistTree';
import {
  getSelectedCropZones,
  mapPropertiesToTreeData
} from 'screens/Reports/helpers/helperFunctions';

const PropertiesSection = () => {
  const { values, setFieldValue } = useFormikContext();
  const seasonIds = useMemo(
    () => values.selectedCropSeasons?.map(cropSeason => cropSeason?.id) ?? [],
    [values.selectedCropSeasons]
  );

  const { dataSorted, loading, reloadData } = usePopulateMapData({
    fetchType: FETCH_TYPES.cropSeasonsMap
  });

  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (seasonIds.length) {
      setFieldValue('selectedCropzones', []);
      reloadData({ seasonIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonIds]);

  // Map API response to tree data
  const treeData = useMemo(() => {
    if (loading || !dataSorted?.properties?.length) return [];

    return mapPropertiesToTreeData(dataSorted.properties);
  }, [dataSorted, loading]);

  // Update form values with checked items
  useEffect(() => {
    if (!dataSorted?.properties?.length || !seasonIds?.length) return;

    const selectedCropZones = getSelectedCropZones(
      dataSorted.properties,
      checked
    );
    setFieldValue('selectedCropzones', selectedCropZones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <div className="flex flex-col items-center bg-white">
      {seasonIds.length > 0 && (
        <div className="w-full">
          <h1
            className="font-body font-semibold text-xl"
            style={{ color: '#14151C' }}
          >
            Properties
          </h1>
          <ChecklistTree
            treeData={treeData}
            loading={loading}
            checked={checked}
            setChecked={setChecked}
          />
        </div>
      )}
    </div>
  );
};

export default PropertiesSection;
