import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useFormikContext } from 'formik';

import { Context } from 'components/Store';
import CropSeasonDateRange from 'components/CropSeasonDateRange';
import CropSeasonDropdown from 'components/CropSeasonDropdown';
import useCropSeasons from 'hooks/useCropSeasons';
import { isFormIncomplete } from 'screens/Reports/helpers/formTouched';
import UpdateReportButton from '../UpdateReportButton';
import PropertiesSection from '../PropertiesSection';
import ProductsSection from '../ProductsSection';
import PlansSection from '../PlansSection';
import MultiPrintAlert from '../MultiPrintAlert';
import ExcelAlert from '../ExcelAlert';
import WorkOrdersSection from '../WorkOrdersSection';

const ReportDetailsForm = ({
  activeReport,
  formConfig,
  formTouched,
  embedToken,
  exportOnly
}) => {
  const { values, setFieldValue, submitForm } = useFormikContext();
  const { cropSeasons, loaded } = useCropSeasons();
  const orgId = Context?._currentValue[0]?.organization?.id;

  const submitDisabled =
    !formTouched(values) || isFormIncomplete(formConfig, values);

  const handleCropSeasonSelect = seasonSelected => {
    setFieldValue(
      'selectedCropSeasons',
      seasonSelected.filter(season => !!season)
    );
  };

  useEffect(() => {
    if (embedToken) {
      setFieldValue('initialSubmit', false);
    }
  }, [orgId, setFieldValue, embedToken]);

  useEffect(() => {
    if (values.initialSubmit || isFormIncomplete(formConfig, values)) return;

    submitForm(values);
    setFieldValue('initialSubmit', true);
  }, [formConfig, values, setFieldValue, submitForm, embedToken]);

  return (
    <div className="z-10 w-101 pl-16px">
      <div
        className="w-full pt-6 bg-white relative"
        style={{ height: '87vh', paddingBottom: '2vh' }}
      >
        <div className="px-8 overflow-y-auto w-full h-full">
          <h2 className="mb-4 text-xl font-semibold text-neutral-1000">
            Report Details
          </h2>
          {formConfig?.showCropSeasons && (
            <div className="mb-6">
              <CropSeasonDropdown
                allCropSeasonsData={cropSeasons}
                csLoaded={loaded}
                onSelect={handleCropSeasonSelect}
                selectedActive
                isRequired={false}
              />
              {formConfig?.showDateRange && (
                <CropSeasonDateRange
                  isRequired={false}
                  className="flex flex-row pb-6 mt-6 gap-5 justify-between relative"
                />
              )}
            </div>
          )}
          {formConfig?.showPlans && (
            <div className="mb-12">
              <PlansSection />
            </div>
          )}
          {formConfig?.showExcelAlert && <ExcelAlert />}
          {formConfig?.showPrintAlert && (
            <MultiPrintAlert values={values} activeReport={activeReport} />
          )}
          {formConfig?.showProperties && (
            <div className="mb-12">
              <PropertiesSection />
            </div>
          )}
          {formConfig?.showWorkOrders && (
            <div className="mb-12">
              <WorkOrdersSection />
            </div>
          )}
          {formConfig?.showProducts && (
            <div className="mb-12">
              <ProductsSection cropSeasons={cropSeasons} />
            </div>
          )}
          {!exportOnly && <UpdateReportButton disabled={submitDisabled} />}
        </div>
      </div>
    </div>
  );
};

ReportDetailsForm.propTypes = {
  activeReport: PropTypes.shape().isRequired,
  formConfig: PropTypes.shape().isRequired,
  formTouched: PropTypes.func.isRequired,
  embedToken: PropTypes.string.isRequired,
  exportOnly: PropTypes.bool.isRequired
};

export default ReportDetailsForm;
