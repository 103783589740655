import { flatten, set, get, last, omitBy } from 'lodash';
import {
  booleanContains,
  clone,
  center,
  centroid,
  lineOverlap
} from '@turf/turf';
import { feature, featureCollection } from '@turf/helpers';
import { generate } from 'shortid';

import { getAllFieldCropZones } from './fieldDataHelpers';
import polygonCut from './polygonCut';

const GEOJSON_TYPES = {
  FEATURE_COLLECTION: 'FeatureCollection',
  FEATURE: 'Feature'
};

export const PRINTABLE_AREA_PADDING = 8;
export const calculateMapPDFScale = () => {
  const screenHeight = window.innerHeight;
  if (screenHeight <= 900) {
    return 1.6;
  }
  if (screenHeight >= 2160) {
    return 1;
  }
  const scaleRange = 1.6 - 1;
  const heightRange = 2160 - 900;
  const scaleFactor = (screenHeight - 900) / heightRange;
  return 1.6 - scaleFactor * scaleRange;
};

export const scaleToMapPDF = value => value * calculateMapPDFScale();

export const isGeoJSONFeatureCollection = geoJSON =>
  geoJSON && geoJSON.type === GEOJSON_TYPES.FEATURE_COLLECTION;

export const isGeoJSONFeature = geoJSON =>
  geoJSON && geoJSON.type === GEOJSON_TYPES.FEATURE;

export const getFeatureCollectionsFromGeoJSONItems = geoJSONItems => {
  if (!geoJSONItems || geoJSONItems.length === 0) {
    return [];
  }
  const allItems = flatten(geoJSONItems);
  const featureCollections = allItems.filter(isGeoJSONFeatureCollection);

  const bundledFeatures = allItems.filter(isGeoJSONFeature);
  const bundledCollection = bundledFeatures.length
    ? [featureCollection(bundledFeatures)]
    : [];

  return [...featureCollections, ...bundledCollection];
};

export const getFeaturesFromGeoJSONItems = geoJSONItems => {
  if (!geoJSONItems || geoJSONItems.length === 0) {
    return [];
  }
  const allItems = flatten(geoJSONItems);
  return allItems.reduce((results, geoJSONItem) => {
    if (isGeoJSONFeatureCollection(geoJSONItem)) {
      return results.concat(geoJSONItem.features);
    }
    if (isGeoJSONFeature(geoJSONItem)) {
      results.push(clone(geoJSONItem));
    }
    return results;
  }, []);
};

export const handleShapeDelete = drawControl => {
  const ids = drawControl.draw.getSelectedIds();
  if (!ids || ids.length === 0) {
    return null;
  }
  drawControl.draw.delete(ids);
  return ids;
};

const calcFeatureContainer = ([feature1, feature2]) => {
  if (!feature1 || !feature2) {
    return {};
  }
  if (booleanContains(feature1, feature2)) {
    return {
      containerFeature: feature1,
      containedFeature: feature2
    };
  }
  if (booleanContains(feature2, feature1)) {
    return {
      containerFeature: feature2,
      containedFeature: feature1
    };
  }
  return {};
};

const createSubtractedFeature = (containerFeature, containedFeature) => {
  const linearRing = containedFeature.geometry.coordinates[0];
  const newFeature = feature(containerFeature.geometry);
  newFeature.geometry.coordinates.push(linearRing);
  return newFeature;
};

// On success returns an object containing the added feature and the id's of the deleted features
// On error returns null
export const handleShapeSubtract = drawControl => {
  const deleted = drawControl.draw.getSelectedIds();
  const features = deleted.map(id => drawControl.draw.get(id));
  const { containerFeature, containedFeature } = calcFeatureContainer(features);
  if (containerFeature && containedFeature) {
    const added = createSubtractedFeature(containerFeature, containedFeature);
    const [addedId] = drawControl.draw.delete(deleted).add(added);
    added.id = addedId;
    return {
      added,
      deleted
    };
  }
  return null;
};

const calcSplitFeature = (featureBeingSplit, lineFeature) => {
  const polygonizedSplitFeatures = [];

  if (lineOverlap(lineFeature, featureBeingSplit)) {
    // Perform the split
    const polygonizedSplit = polygonCut(
      featureBeingSplit.geometry,
      lineFeature.geometry,
      true
    );
    if (polygonizedSplit) {
      for (let j = 0; j < polygonizedSplit.features.length; j += 1) {
        // Delete the hanging shape left over from the previous split
        if (
          polygonizedSplitFeatures[polygonizedSplitFeatures.length - 2] &&
          j === 0
        ) {
          polygonizedSplitFeatures.splice(
            polygonizedSplitFeatures.length - 2,
            1
          );
        }

        polygonizedSplitFeatures.push(polygonizedSplit.features[j]);
      }
    }
  }

  const polygonizedSplitFeatureCollection = featureCollection(
    polygonizedSplitFeatures
  );

  if (!polygonizedSplitFeatureCollection.features.length) {
    return null;
  }

  return polygonizedSplitFeatureCollection;
};

// On success returns an object containing the added features and the id's of the deleted features
// On error returns null
export const handleShapeSplit = (
  drawControl,
  featureIdBeingSplit,
  lineFeature
) => {
  const featureBeingSplit = drawControl.draw.get(featureIdBeingSplit);
  const featureIdsToDelete = [featureBeingSplit.id, lineFeature.id];
  const added = calcSplitFeature(featureBeingSplit, lineFeature);

  drawControl.draw.delete(added ? featureIdsToDelete : lineFeature.id);

  return { added, deleted: featureIdsToDelete };
};

export const getFeatureWithoutId = currentFeature => {
  const newFeature = clone(currentFeature);
  delete newFeature.id;
  return newFeature;
};

const setFeatureIds = (featureItem, parentId) => {
  if (get(featureItem, 'properties.parentId') !== parentId) {
    /* this only updates the parent if parentId is empty,
     * this was causing fields not being edited to change their parent,
     * now that we have fields being rendered that are not strictly under the parent in edit screens
     */
    if (!featureItem?.properties?.parentId)
      set(featureItem, 'properties.parentId', parentId);
    if (featureItem.id) {
      const internalId = last(featureItem.id.split('/'));
      set(featureItem, 'properties.internalId', internalId);
    } else {
      set(featureItem, 'properties.internalId', generate());
      set(
        featureItem,
        'id',
        `${featureItem.properties.parentId}/${featureItem.properties.internalId}`
      );
    }
  } else if (!featureItem.id) {
    set(
      featureItem,
      'id',
      `${featureItem.properties.parentId}/${featureItem.properties.internalId}`
    );
  }
  return featureItem;
};

export const getFeatureWithParentId = (currentFeature, parentLand) => {
  const newFeature = clone(currentFeature);
  if (parentLand && parentLand._id) {
    if (isGeoJSONFeatureCollection(newFeature)) {
      newFeature.features = newFeature.features.map(item =>
        getFeatureWithParentId(item, parentLand)
      );
    } else if (isGeoJSONFeature(newFeature)) {
      setFeatureIds(newFeature, parentLand._id);
    }
  }
  return newFeature;
};

export const getParentLandIdForGeoJSON = geoJSON => {
  if (!geoJSON) {
    return null;
  }
  if (isGeoJSONFeatureCollection(geoJSON)) {
    return geoJSON.features.find(item => getParentLandIdForGeoJSON(item));
  }
  if (isGeoJSONFeature(geoJSON)) {
    return geoJSON.properties ? geoJSON.properties.parentId : null;
  }
  return null;
};

export const getGeoJSONCollectionFeatures = geoJSONCollection => {
  if (!geoJSONCollection?.features) return [];

  return geoJSONCollection.features.filter(
    item => !item.properties || item.properties.includeOnMaps !== false
  );
};

const getAllFieldFeatures = fields => {
  const fieldFeatures = fields.reduce((features, field) => {
    if (field.fieldBoundedArea) {
      features.push(getGeoJSONCollectionFeatures(field.fieldBoundedArea));
    }
    return features;
  }, []);
  return flatten(fieldFeatures);
};

export const getAllFeatures = fields => {
  if (!fields || fields.length === 0) {
    return [];
  }
  const fieldFeatures = getAllFieldFeatures(fields);
  const cropzones = getAllFieldCropZones(fields);

  return cropzones.reduce((allFeatures, cropZone) => {
    if (cropZone.boundedArea && cropZone.boundedArea.length) {
      const { boundedArea } = cropZone;
      return allFeatures.concat(
        boundedArea.map(boundedAreaItem =>
          getFeatureWithParentId(boundedAreaItem, cropZone)
        )
      );
    }
    return allFeatures;
  }, fieldFeatures);
};

export const getAllCollectionFeatures = geoJSONFeatureCollections => {
  const features = geoJSONFeatureCollections.map(
    collection => collection.features
  );
  return flatten(features);
};

export const getCentroidCoordinates = centroidFeature => {
  return centroidFeature && centroidFeature.length > 0
    ? centroidFeature.geometry.coordinates
    : null;
};

export const getCentroidGeoJSON = geoJSON => {
  const centroidFeatures = geoJSON.features.map(f => {
    const featureCentroid = centroid(f);
    return {
      type: 'Feature',
      geometry: featureCentroid.geometry,
      properties: {
        ...f.properties
      }
    };
  });

  return {
    type: 'FeatureCollection',
    features: centroidFeatures
  };
};

export const calculateCentroid = geoJSONFeatureCollection => {
  try {
    const geoJSONPolygons = geoJSONFeatureCollection.features.filter(
      geoJSONFeature => {
        return (
          geoJSONFeature.geometry.type === 'Polygon' ||
          geoJSONFeature.geometry.type === 'MultiPolygon'
        );
      }
    );

    const geoJSONPolygonGeometryCentroids = [];
    for (let i = 0; i < geoJSONPolygons.length; i += 1) {
      geoJSONPolygonGeometryCentroids.push(
        centroid(geoJSONPolygons[i].geometry)
      );
    }

    const centroidFeatureCollection = featureCollection(
      geoJSONPolygonGeometryCentroids
    );
    const returnCentroid = center(centroidFeatureCollection);

    return returnCentroid;
  } catch (error) {
    return [];
  }
};

export const calculateCentroidByEachProperties = (
  geoJSONFeatureCollection,
  properties
) => {
  try {
    const geoJSONPolygons = geoJSONFeatureCollection.features.filter(
      geoJSONFeature => {
        return (
          geoJSONFeature.geometry.type === 'Polygon' ||
          geoJSONFeature.geometry.type === 'MultiPolygon'
        );
      }
    );

    let geoJSONFilteredPolygons = geoJSONPolygons;
    if (properties.length === 1) {
      geoJSONFilteredPolygons = geoJSONPolygons.filter(
        eachPoligons => eachPoligons.properties.propertyId === properties[0].id
      );
    }

    const geoJSONPolygonGeometryCentroids = [];
    for (let i = 0; i < geoJSONFilteredPolygons.length; i += 1) {
      geoJSONPolygonGeometryCentroids.push(
        centroid(geoJSONFilteredPolygons[i].geometry)
      );
    }

    const centroidFeatureCollection = featureCollection(
      geoJSONPolygonGeometryCentroids
    );

    const returnCentroid = center(centroidFeatureCollection);

    return returnCentroid;
  } catch (error) {
    return [];
  }
};

export const getFeaturesCentroidCoordinates = features =>
  calculateCentroid(featureCollection(features)).geometry.coordinates;

export const calculateCentroidForAll = geoJSONItems => {
  const collections = getFeatureCollectionsFromGeoJSONItems(geoJSONItems);
  const features = getAllCollectionFeatures(collections);
  if (features.length === 0) {
    return null;
  }
  const collection = featureCollection(features);
  return centroid(collection);
};

export const pickCentroidCalculation = (
  geoJSONCollection,
  geoJSONFeatureCollections,
  currentCoordinates
) => {
  const calculatedCentroid =
    calculateCentroid(geoJSONCollection) ||
    calculateCentroidForAll(geoJSONFeatureCollections);
  return getCentroidCoordinates(calculatedCentroid) || currentCoordinates;
};

export const getCenterOfBoundingBox = bbox => {
  const [minX, minY, maxX, maxY] = bbox;
  return [(minX + maxX) / 2, (minY + maxY) / 2];
};

export const calculatePrintableAreaBoundingBox = map => {
  // Get map container dimensions
  const mapWidth = map.getContainer().clientWidth;
  const mapHeight = map.getContainer().clientHeight;
  // Use fixed padding or configurable constant

  const padding =
    PRINTABLE_AREA_PADDING * Math.min(window.devicePixelRatio || 1, 2); // Cap pixel ratio scaling
  // Dynamic aspect ratio based on current screen dimensions
  const aspectRatio = mapWidth / mapHeight;
  // Calculate maximum box height using dynamic aspect ratio
  const maxBoxHeight = mapHeight / aspectRatio;
  // Dynamically adjust box height
  const boxHeight = Math.min(mapHeight - 2 * padding, maxBoxHeight);
  // Calculate bounding box corners in pixels
  const topLeft = [padding, (mapHeight - boxHeight) / 2];
  const bottomRight = [mapWidth - padding, (mapHeight + boxHeight) / 2];
  // Convert to geographical coordinates
  const topLeftCoords = map.unproject(topLeft);
  const bottomRightCoords = map.unproject(bottomRight);
  // Return bounding box in [west, south, east, north] format
  return [
    topLeftCoords.lng,
    bottomRightCoords.lat,
    bottomRightCoords.lng,
    topLeftCoords.lat
  ];
};

export const getLandFeatures = parentLand => {
  if (!parentLand) {
    return null;
  }
  return parentLand.fieldBoundedArea
    ? parentLand.fieldBoundedArea.features
    : parentLand.points;
};

// track synchronized features by id
export const getSyncFeatures = features =>
  getFeaturesFromGeoJSONItems(features).map(item => item.id);

// track feature modifications using the UPDATE: prefix to avoid the wrong useEffect
export const getSyncFeaturesForUpdate = (features, updatedId) =>
  features.map(id => (id === updatedId ? `UPDATE:${id}` : id));

// ignore tracking of modified features
export const getSyncFeaturesWithoutUpdates = featureIds =>
  featureIds.map(id => id.replace('UPDATE:', ''));

export const getCentroidFromString = centroidString => {
  const centroidArray = centroidString.split(',');
  centroidArray[0] = Number(centroidArray[0], 10);
  centroidArray[1] = Number(centroidArray[1], 10);

  return centroidArray;
};

export const setFeaturesToLayer = (features = [], layer = 'default') =>
  features.map(({ properties, ...rest }) => ({
    ...rest,
    properties: { ...properties, $layer: layer }
  }));
export const stripInternalProps = features => {
  if (!features?.length) return [];
  const k = features.map(({ properties = {}, ...rest }) => ({
    ...rest,
    properties: omitBy(properties, (val, key) => key.match(/^\$.*/)) // matches props prefixed with $
  }));
  return k;
};
export const flattenFeatures = features => {
  return features.flatMap(ft => {
    if (ft?.geometry?.type === 'MultiPolygon') {
      return ft.geometry.coordinates.map((coords, index) => ({
        type: 'Feature',
        id: `${ft.properties.id}-${index}`,
        properties: ft.properties,
        geometry: { type: 'Polygon', coordinates: coords }
      }));
    }

    return {
      ...ft,
      id: ft.properties.id
    };
  });
};
