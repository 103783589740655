import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, Tree } from 'syngenta-digital-cropwise-react-ui-kit';
import { Spinner } from '@agconnections/grow-ui';

import {
  filterNodesBySearchFunction,
  obtainAllKeys
} from 'screens/Reports/helpers/helperFunctions';
import SearchIcon from 'components/Icons/SearchIcon';

const ChecklistTree = ({
  treeData,
  loading,
  checked,
  setChecked,
  setNumResults
}) => {
  const [expanded, setExpanded] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);

  const handleSelectAll = () => {
    if (checked.length === checkedKeys.length) {
      setChecked([]);
    } else {
      setChecked(checkedKeys);
    }
  };

  const onFilterChange = e => {
    const searchText = e.target.value;
    if (searchText?.length < 3) {
      setFilteredTreeData(treeData);
      return;
    }

    const filterNodes = filterNodesBySearchFunction(searchText);
    setFilteredTreeData(treeData.reduce(filterNodes, []));
  };

  useEffect(() => {
    if (treeData.length) {
      setFilteredTreeData(treeData);
      setInitialLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  useEffect(() => {
    setNumResults(filteredTreeData.length);
    const newCheckedKeys = obtainAllKeys(filteredTreeData);
    setCheckedKeys(newCheckedKeys);
    if (initialLoad) {
      setChecked(newCheckedKeys);
      setInitialLoad(false);
      return;
    }
    setChecked(newCheckedKeys.filter(id => checked.includes(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTreeData]);

  const isAllSelected = checked.length === checkedKeys.length;
  const isIndeterminate =
    checked.length > 0 && checked.length < checkedKeys.length;

  return (
    <div className="filter-container">
      {!loading ? (
        <>
          {treeData?.length > 0 && (
            <>
              <div className="mt-4 mb-2">
                <Input
                  prefix={<SearchIcon />}
                  placeholder="Search..."
                  type="search"
                  onChange={onFilterChange}
                />
              </div>
              <div className="mt-2 mb-1 flex">
                <Checkbox
                  data-testid="checklist-tree-select-all"
                  size="small"
                  onChange={handleSelectAll}
                  checked={isAllSelected}
                  indeterminate={isIndeterminate}
                >
                  Select All
                </Checkbox>
              </div>

              <Tree
                checkable
                treeData={filteredTreeData}
                checkedKeys={checked}
                onCheck={setChecked}
                expandedKeys={expanded}
                onExpand={setExpanded}
                selectable={false}
                showIcon={false}
              />
            </>
          )}
        </>
      ) : (
        <div className="mt-40px">
          <Spinner size={72} />
        </div>
      )}
    </div>
  );
};

ChecklistTree.defaultProps = {
  treeData: [],
  loading: false,
  setNumResults: () => {}
};
ChecklistTree.propTypes = {
  treeData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  setChecked: PropTypes.func.isRequired,
  setNumResults: PropTypes.func
};

export default ChecklistTree;
