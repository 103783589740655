import { useContext, useEffect, useState, useCallback } from 'react';
import { tasks as tasksApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import PAGINATION_SIZE from 'helpers/globalConstants';
import { Context } from 'components/Store';

const useTasks = defaultParams => {
  const [, dispatch] = useContext(Context);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [request] = useState({
    pageNo: 0,
    size: PAGINATION_SIZE,
    seasonIdsCommaSeparated: JSON.parse(
      localStorage.getItem('selectedCropSeasons')
    ).join(','),
    ...defaultParams
  });

  const loadTasks = useCallback(
    params => {
      setLoading(true);

      const { promise, cancel } = tasksApi.fetch(
        null,
        {
          ...request,
          ...params
        },
        {},
        null,
        false
      );
      promise
        .then(({ data }) => {
          setTasks(data.results);
        })
        .catch(catchCancel)
        .catch(err => {
          parseServerError(dispatch)(err);
        })
        .finally(() => setLoading(false));

      return cancel;
    },
    [request, dispatch]
  );

  useEffect(() => {
    const cancel = loadTasks();

    return () => {
      cancel();
    };
  }, [loadTasks]);

  return {
    tasks,
    loading,
    loadTasks
  };
};

export default useTasks;
