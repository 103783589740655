import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import MapboxLogo from 'assets/mapbox_logo_black.svg';
import CropwiseFinancialsLogo from 'assets/cropwise_financials_logo_black.svg';
import { scaleToMapPDF as scale } from 'screens/Property/helpers/mapApiHelpers';
import PropertyMapFooter from '../PropertyMapFooter';

export const MAP_PREVIEW_ID = 'map-pdf-preview';
export const LEGEND_PREVIEW_ID = 'legend-pdf-preview';

export const MAP_EXPORTABLE_ID = 'map-pdf-exportable';
export const LEGEND_EXPORTABLE_ID = 'legend-pdf-exportable';

const PDFPage = ({ id, pageNo, children, mapLegend = null }) => {
  const pageWidth = scale(792); // 11 inches in pixels at 72 DPI
  const pageHeight = scale(612); // 8.5 inches in pixels at 72 DPI
  const marginSize = scale(36); // 0.5 inch margins (72px

  return (
    <div
      id={id}
      className="relative flex flex-col bg-white shadow-sm-card"
      style={{
        width: pageWidth,
        height: pageHeight,
        padding: marginSize,
        marginTop: id === MAP_PREVIEW_ID ? 0 : 8
      }}
    >
      <div className="flex flex-grow flex-col">{children}</div>

      <Row
        className="bg-white items-end"
        justify="space-between"
        style={{
          height: scale(72)
        }}
      >
        <PropertyMapFooter />
        <Col>
          <Row className="items-end" gutter={8}>
            <img
              data-testid="mapbox-logo"
              src={MapboxLogo}
              alt="logo"
              width={scale(48)}
            />
            <img
              data-testid="cropwise-logo"
              src={CropwiseFinancialsLogo}
              alt="logo"
              width={scale(67)}
            />
            {mapLegend}
            {pageNo && (
              <Col className="ml-2" style={{ fontSize: scale(10) }}>
                {pageNo}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

PDFPage.propTypes = {
  id: PropTypes.string,
  pageNo: PropTypes.number,
  children: PropTypes.node.isRequired,
  mapLegend: PropTypes.node
};

PDFPage.defaultProps = {
  id: '',
  pageNo: null,
  mapLegend: null
};

export default PDFPage;
