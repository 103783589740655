import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Context } from 'components/Store';
import { cloneDeep, flatten, isEqual, uniqBy } from 'lodash';
import {
  flattenFeatures,
  getFeaturesCentroidCoordinates,
  getFeaturesFromGeoJSONItems
} from 'screens/Property/helpers/mapApiHelpers';
import { paths } from 'routes/paths';
import {
  IMPORTED_FIELDS,
  IS_FIELD_IMPORT_SUCCESSFUL,
  UPLOADED_FILES
} from 'reducers/reducer';
import { roundValue } from 'helpers/unitConversionHelpers';
import {
  convertFeaturesToBaseGeometry,
  getUpdatedBoundingBox,
  getZoomByBounds,
  handleOverlappedCoordinates,
  mappingShape
} from '../../../helpers/propertyDataHelpers';
import useFieldData from '../../../hooks/useFieldData';
import useFarmData from '../../../hooks/useFarmData';
import { CREATION_STATES } from '../helpers.js/constants';
import { fieldToFeature } from '../../ImportPropertyModal/fieldImporterHelper';

const usePropertyFeatures = (fieldId, action, history, state) => {
  const fieldLoaded = useRef(false);
  const navGuardDisabled = useRef(false);
  const boundsZoomStablished = useRef(false);

  const { farms, createFarm, loading: isLoadingFarms } = useFarmData();

  const isEdition = action === 'edit';
  const {
    fieldGeoJSONCollection,
    loading: isLoading,
    zoom: fieldZoom,
    setZoom,
    loadField,
    createField,
    updateField
  } = useFieldData(null, false, isEdition);

  const [{ importedFields }, dispatch] = useContext(Context);
  const [formValues, setFormValues] = useState({
    name: '',
    reportedArea: '',
    propertyId: ''
  });

  const [geoJSONCollection, setGeoJSONCollection] = useState(
    fieldGeoJSONCollection
  );
  const [features, setFeatures] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [shapeIds, setShapeIds] = useState(fieldId ? null : []);
  const [
    redirectToPropertiesLanding,
    setRedirectToPropertiesLanding
  ] = useState(!!fieldId);
  const [creationState, setCreationState] = useState(
    CREATION_STATES.DRAWING_MODE
  );
  const [redirect, setRedirect] = useState(null);
  const [newFieldFeatures, setNewFieldFeatures] = useState([]);
  const [fieldToEdit, setFieldToEdit] = useState();
  const [chkNavGuardDisabled, setChkNavGuardDisabled] = useState(false);
  const [isBackArrowClicked, setIsBackArrowClicked] = useState(false);
  const [fieldsFiltered, setFieldsFiltered] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);

  useEffect(() => {
    if (isEdition && fieldId && !fieldLoaded.current) loadField(fieldId);
  }, [isEdition, fieldId, loadField]);

  useEffect(() => {
    if (!fieldId) {
      if (!state) return;
      const { selectedId } = state;
      if (selectedId) {
        setFormValues(previousFormValues => ({
          ...previousFormValues,
          propertyId: selectedId
        }));
      }
    }
  }, [fieldId, state]);

  const canShowMap = useMemo(() => {
    if (isLoading) return false;
    if (fieldId) return fieldsFiltered;
    if (!geoJSONCollection.features.length) return true;
    return !isEqual(fieldZoom, [4]) || !!geoJSONCollection.features.length;
  }, [
    fieldId,
    fieldsFiltered,
    fieldZoom,
    geoJSONCollection.features,
    isLoading
  ]);

  const fitBounds = useMemo(
    () => getUpdatedBoundingBox(undefined, geoJSONCollection, false),
    [geoJSONCollection]
  );

  const saveField = useCallback(
    async values => {
      const field = {
        ...values
      };

      const featuresToGetGeometry = newFieldFeatures.map(ft => {
        if (ft.geometry.type === 'Polygon') {
          return {
            ...ft,
            geometry: {
              type: 'MultiPolygon',
              coordinates: [ft.geometry.coordinates]
            }
          };
        }

        return ft;
      });

      if (featuresToGetGeometry.length) {
        field.coordinates = getFeaturesCentroidCoordinates(
          featuresToGetGeometry
        ).toString();
      }
      field.geometry = convertFeaturesToBaseGeometry(featuresToGetGeometry);

      field.geometry.coordinates = flatten(field.geometry.coordinates);

      field.geometry.coordinates = handleOverlappedCoordinates(
        field.geometry.coordinates
      );

      field.reportedArea = field.reportedArea || 0;
      field.reportedAreaUnit = 'acre';

      if (!fieldId || state?.label === 'FROM_IMPORT_FIELD') {
        const { data: createdField } = await createField(field);
        return createdField;
      }

      const { data: updatedField } = await updateField(field, fieldId);
      return updatedField;
    },
    [createField, fieldId, newFieldFeatures, state, updateField]
  );

  const saveProperty = useCallback(
    async (values, redirectToCropZone, setSubmitting) => {
      setCreationState(CREATION_STATES.SAVING);
      let createdField = null;
      let path = paths.properties;

      try {
        createdField = await saveField(values);
        if (redirectToCropZone) {
          path = `${paths.properties}/create/${createdField.id}/cropzone`;
          setRedirect(path);
        }

        if (redirectToPropertiesLanding) {
          if (state?.label === 'FROM_IMPORT_FIELD') {
            const removingTheFieldAfterSuccess = importedFields.filter(
              importedField => importedField.fieldId !== fieldId
            );
            if (removingTheFieldAfterSuccess.length > 0) {
              dispatch({
                type: IS_FIELD_IMPORT_SUCCESSFUL,
                payload: true
              });
            } else {
              dispatch({
                type: IS_FIELD_IMPORT_SUCCESSFUL,
                payload: false
              });
              dispatch({
                type: UPLOADED_FILES,
                payload: removingTheFieldAfterSuccess
              });
            }
            dispatch({
              type: IMPORTED_FIELDS,
              payload: removingTheFieldAfterSuccess
            });
          }
          setRedirect(path);
        } else if (
          !redirectToPropertiesLanding &&
          !path?.includes('cropzone')
        ) {
          window.location.reload(false);
        }
      } catch (err) {
        setIsToastVisible(true);
        setSubmitting(false);
        setCreationState(CREATION_STATES.GETTING_STARTED);
      }
    },
    [
      fieldId,
      importedFields,
      saveField,
      state,
      dispatch,
      redirectToPropertiesLanding
    ]
  );

  const handleModalConfirm = useCallback(
    async (values, setSubmitting) => {
      setSubmitting(true);
      setOpenModal(false);

      await saveProperty(values, null, setSubmitting);

      setSubmitting(false);
    },
    [saveProperty]
  );

  const handleModalCancel = useCallback(
    async (values, setSubmitting) => {
      setSubmitting(true);
      navGuardDisabled.current = true;
      await saveProperty(values, true, setSubmitting);
    },
    [saveProperty]
  );

  const handleModalClose = useCallback(setSubmitting => {
    setOpenModal(false);
    setSubmitting(false);
  }, []);

  const handleSaveAndCreateCropZone = useCallback(
    async (values, setSubmitting) => {
      setSubmitting(true);
      setRedirectToPropertiesLanding(false);

      await saveProperty(values, true, setSubmitting);
    },
    [saveProperty]
  );

  const handleSaveAndRedirectToProperties = useCallback(
    (values, setSubmitting) => {
      setSubmitting(true);
      setRedirectToPropertiesLanding(true);
      if (!fieldId) {
        setOpenModal(true);
        return;
      }
      saveProperty(values, false, setSubmitting);
    },
    [fieldId, saveProperty]
  );
  const handleCreateFarm = useCallback(
    name => {
      return createFarm({
        name,
        timeZone: 'US/Central',
        referencePoint: {
          type: 'Point',
          coordinates: [0, 0]
        }
      });
    },
    [createFarm]
  );

  const extractFeatureNames = useCallback(
    async feature => {
      const { farmName } = feature.properties;
      const existingFarm = farms.find(({ name }) => name === farmName);
      try {
        const newFarm =
          farmName && !existingFarm
            ? await handleCreateFarm(farmName)
            : existingFarm;

        setFormValues(previousFormValues => ({
          ...previousFormValues,
          name: feature.properties.fieldName || previousFormValues.name,
          propertyId: newFarm?.id || previousFormValues.propertyId
        }));

        return true;
      } catch (err) {
        return false;
      }
    },
    [farms, handleCreateFarm]
  );

  const handleFeaturesChange = useCallback(
    async newFeatures => {
      const featureWithNewNames = newFeatures.find(
        ({ properties }) =>
          properties?.farmName ||
          properties?.fieldName ||
          properties?.cropZoneName
      );
      if (featureWithNewNames) {
        await extractFeatureNames(featureWithNewNames);
      }
      const newShapes = getFeaturesFromGeoJSONItems(newFeatures);
      setFeatures(uniqBy(newShapes, 'id'));
    },
    [extractFeatureNames]
  );

  const filterFields = useCallback(() => {
    let selectedField = [];
    if (state?.label === 'FROM_IMPORT_FIELD') {
      const { selectedField: fieldFromImport } = state;
      selectedField = fieldToFeature(fieldFromImport);
      setFormValues({
        propertyId: farms.find(({ name }) => name === fieldFromImport?.farm)
          ?.id,
        name: fieldFromImport?.name,
        reportedArea: ''
      });
    } else {
      selectedField = fieldGeoJSONCollection?.features?.find(
        ft => ft.properties.id === fieldId
      );
      setFormValues({
        propertyId: selectedField?.properties?.propertyId,
        name: selectedField?.properties?.name,
        reportedArea: roundValue(selectedField?.properties?.reportedArea)
      });
    }

    const defaultField = cloneDeep(selectedField);

    defaultField.properties.$layer = 'default';
    selectedField.properties.$layer = 'selected';
    selectedField.id = fieldId;
    if (selectedField.geometry.coordinates.length === 1) {
      selectedField = mappingShape(selectedField);
    }

    const fields = [defaultField, selectedField];

    const flattenFields = flattenFeatures(fields);

    const customFitFields = getUpdatedBoundingBox(
      undefined,
      { type: 'FeatureCollection', features: flattenFields },
      false
    );

    const boundsZoom = getZoomByBounds(customFitFields);
    setZoom([boundsZoom - 1]);
    setFieldToEdit(selectedField?.properties);
    setGeoJSONCollection({
      type: 'FeatureCollection',
      features: flattenFields
    });
    setNewFieldFeatures([selectedField]);
    setShapeIds(
      flattenFields
        .filter(ft => ft.properties.$layer === 'selected')
        .map(ft => ft.id)
    );
    setFeatures(flattenFields);
    setFieldsFiltered(true);
  }, [fieldGeoJSONCollection, fieldId, farms, setZoom, state]);

  useEffect(() => {
    if (fieldId && !isLoading && !isLoadingFarms && farms.length > 0) {
      fieldLoaded.current = true;
      filterFields();
    }
  }, [fieldId, isLoading, farms, isLoadingFarms, filterFields]);

  useEffect(() => {
    if (!boundsZoomStablished.current && !fieldId) {
      const boundsZoom = getZoomByBounds(fitBounds);

      if (!Number.isNaN(boundsZoom)) {
        boundsZoomStablished.current = true;
        setZoom([boundsZoom - 1]);
      }
    }
  }, [fitBounds, fieldId, setZoom]);

  const handleFeaturesSelect = useCallback(
    selectedIds => {
      const fields = features.filter(ft => selectedIds.includes(ft.id));
      const uniqFields = uniqBy(fields, 'id');
      setNewFieldFeatures(uniqFields);
    },
    [features]
  );

  const handleBackArrowCancel = () => {
    setIsBackArrowClicked(false);
    setChkNavGuardDisabled(false);
  };

  const handleCancelBackButton = () => {
    if (fieldId) {
      setChkNavGuardDisabled(true);
      history.goBack();
    } else {
      setRedirect(paths.properties);
    }
  };

  return {
    redirect,
    setRedirect,
    formValues,
    creationState,
    newFieldFeatures,
    openModal,
    handleModalCancel,
    handleModalClose,
    handleModalConfirm,
    isBackArrowClicked,
    handleBackArrowCancel,
    chkNavGuardDisabled,
    navGuardDisabled,
    handleSaveAndCreateCropZone,
    handleSaveAndRedirectToProperties,
    handleCancelBackButton,
    setChkNavGuardDisabled,
    setIsBackArrowClicked,
    handleCreateFarm,
    farms,
    fieldToEdit,
    geoJSONCollection,
    canShowMap,
    shapeIds,
    handleFeaturesChange,
    handleFeaturesSelect,
    fieldZoom,
    setZoom,
    setNewFieldFeatures,
    isToastVisible,
    setIsToastVisible,
    saveProperty
  };
};

export default usePropertyFeatures;
