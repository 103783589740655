import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const SelectTypeTable = ({
  applicationType,
  handleSelect,
  itemsApplicationStrategy
}) => {
  return (
    <Select
      defaultValue={`${applicationType}`}
      style={{
        width: 'auto'
      }}
      onChange={handleSelect}
      options={itemsApplicationStrategy.map(item => ({
        value: item.key,
        label: item.value,
        display: item.display
      }))}
    />
  );
};

SelectTypeTable.propTypes = {
  applicationType: PropTypes.string,
  handleSelect: PropTypes.func,
  itemsApplicationStrategy: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      display: PropTypes.string
    })
  )
};

SelectTypeTable.defaultProps = {
  applicationType: '',
  handleSelect: () => {},
  itemsApplicationStrategy: []
};

export default SelectTypeTable;
