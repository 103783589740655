import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NATIONAL_ZOOM } from 'screens/Property/helpers/constants';
import {
  calculateCentroid,
  flattenFeatures
} from 'screens/Property/helpers/mapApiHelpers';
import {
  getUpdatedBoundingBox,
  getZoomByBounds,
  mappingShape
} from 'screens/Property/helpers/propertyDataHelpers';
import useFieldData from 'screens/Property/hooks/useFieldData';

const useCropZoneFeatures = (fieldId, cropzoneId) => {
  const {
    fieldGeoJSONCollection: fieldsAndAreasGeoJSONCollection,
    loading: isLoading,
    zoom = NATIONAL_ZOOM,
    setZoom,
    loadField
  } = useFieldData(null, false);

  const [isSaving, setIsSaving] = useState(false);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedFeatureIds, setSelectedFeatureIds] = useState();
  const [cropzone, setCropzone] = useState(null);
  const [field, setField] = useState(null);
  const [initialFeatures, setInitialFeatures] = useState({});

  const mapMultiPolygon = useRef(ft => {
    if (ft.geometry.coordinates.length === 1) {
      return mappingShape(ft);
    }

    return ft;
  });

  const geoJSONCentroid = useMemo(() => {
    if (initialFeatures.features) {
      return calculateCentroid(initialFeatures);
    }

    return null;
  }, [initialFeatures]);

  const mapCoordinates = useMemo(() => geoJSONCentroid?.geometry?.coordinates, [
    geoJSONCentroid
  ]);

  useEffect(() => {
    if (fieldId) loadField(fieldId, false, true);
  }, [fieldId, loadField]);

  useEffect(() => {
    if (isLoading || !fieldsAndAreasGeoJSONCollection) return;
    const newFeatures = [];
    const fieldSelected = fieldsAndAreasGeoJSONCollection.features.find(
      ft => ft.properties.id === fieldId
    );

    if (fieldSelected) {
      fieldSelected.properties.$layer = 'default';
      fieldSelected.id = fieldId;
      setField(fieldSelected.properties);
      newFeatures.push(mapMultiPolygon.current(fieldSelected));

      fieldSelected.properties.cropzones.forEach(cz => {
        const selectedCropzone = fieldsAndAreasGeoJSONCollection.features.find(
          ft => ft.properties.id === cz.id
        );

        let layer = 'default';

        if (selectedCropzone.properties.id === cropzoneId) {
          layer = 'selected';
          selectedCropzone.id = cropzoneId;
          setCropzone(selectedCropzone.properties);
        }

        selectedCropzone.properties.$layer = layer;

        newFeatures.push(mapMultiPolygon.current(selectedCropzone));
      });

      if (!cropzoneId) {
        const id = self.crypto.randomUUID();
        newFeatures.push(
          mapMultiPolygon.current({
            ...fieldSelected,
            id,
            properties: {
              ...fieldSelected.properties,
              id,
              $layer: 'selected'
            }
          })
        );
      }

      const flattenedFeatures = flattenFeatures(newFeatures);

      const featureCollection = {
        type: 'FeatureCollection',
        features: flattenedFeatures
      };

      const customFitFields = getUpdatedBoundingBox(
        undefined,
        featureCollection,
        false
      );

      const boundsZoom = getZoomByBounds(customFitFields);

      setZoom([boundsZoom - 1]);

      setFeatures(flattenedFeatures);
      setSelectedFeatureIds(
        flattenedFeatures
          .filter(ft => ft.properties.$layer === 'selected')
          .map(ft => ft.id)
      );
      setInitialFeatures(featureCollection);
    }
  }, [
    fieldsAndAreasGeoJSONCollection,
    cropzoneId,
    fieldId,
    isLoading,
    setZoom
  ]);

  const handleFeaturesChange = useCallback(
    newFeatures => setFeatures(_.uniqBy(newFeatures, 'id')),
    [setFeatures]
  );

  const handleSelectedFeatures = useCallback(
    featuresId => {
      const selectedCropzones = features.filter(ft =>
        featuresId.includes(ft.id)
      );

      setSelectedFeatures(selectedCropzones);
    },
    [features]
  );
  return {
    cropzone,
    features,
    field,
    initialFeatures,
    isLoading,
    isSaving,
    mapCoordinates,
    selectedFeatures,
    selectedFeatureIds,
    setInitialFeatures,
    setIsSaving,
    setZoom,
    zoom,
    handleFeaturesChange,
    handleSelectedFeatures
  };
};

export default useCropZoneFeatures;
